import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"

const query = graphql`
  {
    file(relativePath: { eq: "me.png" }) {
      id
      childImageSharp {
        fixed(width: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Hero = () => {
  const {
    file: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(query)

  return (
    <section className="intro-wrapper">
      <div className="wrapper">
        <div className="intro-card center">
          <div className="intro-image">
            <Image fixed={fixed} />
          </div>
          <div className="name">Sadat Jubayer</div>

          <span className="title">
            <span className="title-chip">Web Developer</span>
            <span className="title-chip">UI/UX Designer</span>
            <span className="title-chip">JavaScript Enthusiast</span>
          </span>

          <p className="detail">
            Freelance developer with strong analytical skills and proficiency.
            Passionate about learning and exploring new technologies. Love to
            write clean and maintainable codes for web and mobile applications
            and specialized in UI/UX.
          </p>
          <div className="socials">
            <SocialLinks />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
