import React from "react"
import {
  FaFacebookSquare,
  FaGithubSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa"

import { GrMail } from "react-icons/gr"

const data = [
  {
    id: 1,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/sadatjubayer/",
  },
  {
    id: 2,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://twitter.com/SadatJubayer",
  },
  {
    id: 3,
    icon: <FaFacebookSquare className="social-icon"></FaFacebookSquare>,
    url: "https://www.facebook.com/SadatJubayer",
  },
  {
    id: 4,
    icon: <FaGithubSquare className="social-icon"></FaGithubSquare>,
    url: "https://www.github.com/SadatJubayer",
  },
  {
    id: 5,
    icon: <GrMail className="social-icon"></GrMail>,
    url: "mailto:contact@sadatjubayer.com",
  },
]
const links = data.map(link => {
  return (
    <div key={link.id}>
      <a
        href={link.url}
        rel="noopener noreferrer"
        target="_blank"
        className="social-link"
      >
        {link.icon}
      </a>
    </div>
  )
})

export default ({ styleClass }) => {
  return (
    <div className={`social-links ${styleClass ? styleClass : ""}`}>
      {links}
    </div>
  )
}
