import React from "react"
import { FaReact, FaDatabase, FaSlack } from "react-icons/fa"

import { AiOutlineCodeSandbox, AiFillCode } from "react-icons/ai"
import { GiPalette } from "react-icons/gi"

export default [
  {
    id: 1,
    icon: <AiFillCode className="skill-icon"></AiFillCode>,
    title: "Programming Languages",
    names:
      "JavaScript (ES6+), TypeScript, Python, PHP, C/C++, Java, C#, Go, Dart",
  },
  {
    id: 2,
    icon: <FaReact className="skill-icon"></FaReact>,
    title: "Libraries & Frameworks",
    names:
      "React, Gatsby, NextJS, NodeJS, Express, Flask, Django, Bootstrap, Material-UI, Tailwind, React Native, Flutter etc",
  },
  {
    id: 3,
    icon: <FaDatabase className="skill-icon"></FaDatabase>,
    title: "Databases",
    names: "MongoDB, MySQL, PostgreSQL, Oracle, MSSQL, SQLite",
  },
  {
    id: 4,
    icon: <AiOutlineCodeSandbox className="skill-icon"></AiOutlineCodeSandbox>,
    title: "Dev Tools",
    names:
      "Git, Github, Zira, Trello, WebPack, Docker, Firebase, GraphQL, REST etc",
  },
  {
    id: 5,
    icon: <GiPalette className="skill-icon"></GiPalette>,
    title: "Design Tools",
    names: "Adobe XD, Figma, Adobe InDesign, Illustrator",
  },
  {
    id: 6,
    icon: <FaSlack className="skill-icon"></FaSlack>,
    title: "Miscellaneous",
    names: "CLI, HTML, CSS, SASS, Markdown",
  },
]
