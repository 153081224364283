import React from "react"
import skillSets from "../constants/skills"
import SkillCard from "./SkillCard"
import { Link } from "gatsby"

import Title from "./Title"

function Skills() {
  return (
    <section className="skills-wrapper" id="skills">
      <div className="wrapper">
        <Title title="Skills I Have" />
        <div className="subheading">Technologies I have worked with</div>
        <div className="card-container mt-1">
          {skillSets.map(skill => (
            <SkillCard
              key={skill.id}
              icon={skill.icon}
              title={skill.title}
              names={skill.names}
            />
          ))}
        </div>
        <div className="center mt-2">
          <Link to="/uses" className="btn">
            My Current Stack
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Skills
