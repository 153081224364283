import React, { useState } from "react"

import Title from "../components/Title"

function Contact() {
  // const [name, setName] = useState("")
  // const [email, setEmail] = useState("")
  // const [message, setMessage] = useState("")
  // const [error, setError] = useState("")

  const [status, setStatus] = useState("")
  const [sending, setSending] = useState(false)

  const submitForm = ev => {
    ev.preventDefault()
    setSending(true)
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus("SUCCESS")
        setSending(false)
      } else {
        setStatus("ERROR")
        setSending(false)
      }
    }
    xhr.send(data)
  }

  return (
    <section className="contact-wrapper" id="contact">
      <div className="wrapper">
        <Title title="Contact" />
        <div className="subheading">
          If you have any questions in mind, feel free to ask me. I am always
          here to make you happy.
        </div>

        <form
          onSubmit={submitForm}
          action="https://formspree.io/xpzypgob"
          method="POST"
          className="contact-form"
        >
          <input required type="text" name="Name" placeholder="Name" id="" />
          <input required type="email" name="Email" placeholder="Email" id="" />
          <textarea
            required
            name="Message"
            placeholder="Your message..."
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <button type="submit" className="btn contact-btn">
            {sending ? "Sending..." : "Send Message"}
          </button>

          {status === "SUCCESS" && (
            <p className="center">Thank You! I will catch you soon!</p>
          )}
          {status === "ERROR" && (
            <p className="center">Ooops! There was an error.</p>
          )}
        </form>
      </div>
    </section>
  )
}

export default Contact
