import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Skills from "../components/Skills"
import Projects from "../components/Projects"
import Testimonial from "../components/Testimonials"
import Contact from "../components/Contact"
import ScrollTopArrow from "../components/ScrollToTop"
import SEO from "../components/SEO"

export default ({ data }) => {
  const {
    allContentfulProject: { nodes: projects },
  } = data

  return (
    <Layout>
      <SEO title="Home" description="All about Sadat Jubayer" />
      <ScrollTopArrow />
      <Hero />
      <Services />
      <Skills />
      <Projects
        projects={projects}
        subheading="Recent projects I have done"
        title="Projects"
        showLink
      />
      <Testimonial />
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulProject(
      limit: 6
      filter: { isFeatured: { eq: true } }
      sort: { fields: serial }
    ) {
      nodes {
        git
        id
        link
        techs
        title
        cover {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        description {
          description
        }
      }
    }
  }
`
