import React from "react"
import icon from "../assets/quote.svg"
import arrow from "../assets/arrow.svg"

const Testimonial = ({ quote, wisher, title }) => {
  return (
    <div className="testimonial-card">
      <div className="card-margin">
        <img src={icon} alt="" className="icon" />
        <blockquote>{quote}</blockquote>
        <img src={arrow} alt="" className="arrow" />
        <div className="wisher">
          <h5>{wisher}</h5>
          <p>{title}</p>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
