import React from "react"

import { IconContext } from "react-icons"

function SkillCard({ icon, title, names }) {
  return (
    <IconContext.Provider
      value={{ color: "#fff", size: "2.5em", className: "skill-icon" }}
    >
      <div className="skill-card">
        {/* <img src={icon} alt="" className="card-icon" /> */}
        {icon}
        <div className="card-body">
          <div className="title">{title}</div>
          <div className="text">{names}</div>
        </div>
      </div>
    </IconContext.Provider>
  )
}

export default SkillCard
