import React from "react"

import design from "../assets/do/design.svg"
import front from "../assets/do/frontend.svg"
import back from "../assets/do/backend.svg"
import mobile from "../assets/do/mobile.svg"

import Title from "./Title"
import DoCard from "./DoCard"

const Services = () => {
  return (
    <section className="do-wrapper">
      <div className="wrapper">
        <Title title="What I Do" />
        <div className="do-cards-container">
          <DoCard text="User Interface Design" color="#FF4C60" icon={design} />
          <DoCard text="Frontend Development" color="#47535E" icon={front} />
          <DoCard text="Backend Development" color="#6C6CE5" icon={back} />
          <DoCard text="Mobile App Development" color="#E2AA00" icon={mobile} />
        </div>
      </div>
    </section>
  )
}

export default Services
