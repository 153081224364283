import React, { useState, useEffect } from "react"
import { FaArrowCircleUp } from "react-icons/fa"

import { window } from "browser-monads"

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  useEffect(() => {
    return function () {
      window.removeEventListener("scroll", checkScrollTop)
    }
  }, [showScroll])

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  window.addEventListener("scroll", checkScrollTop)

  return (
    <FaArrowCircleUp
      title="Go to top"
      className="scrollTop"
      onClick={scrollTop}
      style={{ height: 40, display: showScroll ? "flex" : "none" }}
    />
  )
}

export default ScrollTopArrow
