import React from "react"

function DoCard({ text, icon, color }) {
  return (
    <div className="do-card card1" style={{ background: color }}>
      <img className="card-image" src={icon} alt="" />
      <div className="card-text">{text}</div>
    </div>
  )
}

export default DoCard
