import React from "react"
import Title from "./Title"
import Testimonial from "./Testimonial"
import Slider from "react-slick"
import { Helmet } from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTestimonial(sort: { fields: serial }) {
        nodes {
          wisher
          id
          title
          quote {
            quote
          }
        }
      }
    }
  `)

  const {
    allContentfulTestimonial: { nodes: testimonials },
  } = data

  const settings = {
    className: "center",
    infinite: true,
    speed: 1000,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  }

  return (
    <section className="testimonial-wrapper">
      <Helmet>
        <link
          async
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          async
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div className="wrapper">
        <Title title="Testimonials" />
        <div className="subheading">What professionals said about me</div>
        <Slider {...settings}>
          {testimonials.map(t => (
            <Testimonial
              key={t.id}
              quote={t.quote.quote}
              wisher={t.wisher}
              title={t.title}
            />
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Testimonials
